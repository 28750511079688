<template>
  <div class="layout">
    <el-container>
      <el-header>
        <i class="logo"></i>
        <span class="title">{{cmsTitle}}</span>
        <div class="userinfo">
          <span class="username">{{userName}}</span>
          <!-- <el-dropdown
            trigger="click"
            class="username"
            @command="handleCommand"
          >
            <span class="el-dropdown-link">
              {{userName}}
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="changepw">修改密码</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown> -->
          <!-- <el-button class="logout" type="text" @click="logout">登出</el-button> -->
          <el-button type="warning" @click="logout">登出</el-button>
        </div>
      </el-header>
      <el-container class="maxheight">
        <aside>
          <el-menu
            :default-openeds="defaultOpeneds"
            :default-active="activeIndex"
            router
            :collapse="isCollapse"
            @open="openMenu"
          >
            <!-- <el-submenu index="one">
                        <template slot="title">
                            <i class="el-icon-location"></i>
                            <span slot="title">导航一</span>
                        </template>
                        <el-menu-item-group>
                            <el-menu-item index="one-1">选项1</el-menu-item>
                            <el-menu-item index="one-2">选项2</el-menu-item>
                        </el-menu-item-group>

                        <el-submenu index="one-4">
                            <span slot="title">选项4</span>
                            <el-menu-item index="one-4-1">选项1</el-menu-item>
                        </el-submenu>
                    </el-submenu>

                    <el-submenu index="two">
                        <template slot="title">
                            <i class="el-icon-location"></i>
                            <span slot="title">导航二</span>
                        </template>
                        <el-menu-item-group>
                            <el-menu-item index="two-1">选项1</el-menu-item>
                            <el-menu-item index="two-2">选项2</el-menu-item>
                        </el-menu-item-group>
                    </el-submenu>

                    <el-menu-item index="4">
                        <i class="el-icon-setting"></i>
                        <span slot="title">导航四</span>
                    </el-menu-item> -->
            <template v-for="item in menuData">
              <el-submenu
                v-if="item.menuItem.length > 0"
                :key="item.index"
                :index="item.index"
              >
                <template slot="title">
                  <i :class="item.icon"></i>
                  <span slot="title">{{ item.title }}</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item
                    v-for="subitem in item.menuItem"
                    :key="subitem.index"
                    :index="subitem.index"
                  >
                    {{ subitem.title }}
                  </el-menu-item>
                </el-menu-item-group>
              </el-submenu>

              <el-menu-item
                v-if="item.menuItem.length == 0"
                :key="item.index"
                :index="item.index"
              >
                <i class="el-icon-setting"></i>
                <span slot="title">{{ item.title }}</span>
              </el-menu-item>
            </template>
          </el-menu>
        </aside>
        <el-container>
          <el-main>
            <keep-alive :include="includedComponents">
              <router-view></router-view>
            </keep-alive>
          </el-main>
          <el-footer>Powered by Mtelnet</el-footer>
        </el-container>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import { allRoutes, resetRouter } from "@/router";
import { getStore,removeStore ,setStore} from '@/utils/storage';
export default {
  data() {
    return {
      userName:"",
      isCollapse: false,
      defaultOpeneds: [],
      includedComponents:[],
      cmsTitle:getStore("pageTitle")
    };
  },
  computed: {
    menuData() {
      let menuArr = [];
      let arr = allRoutes.filter(
        item => item.path !== "" && item.meta.type !== "hide"
      );
      let permissionsarr = JSON.parse(getStore("permissions"));
      let newArr=[];
      arr.forEach(item=> {
      for(let i = 0;i<permissionsarr.length;i++) {
          if(item.meta && item.meta.code == permissionsarr[i].code && permissionsarr[i].permissionType != 0 && permissionsarr[i].permissionType != null) {
            item.permissionType = permissionsarr[i].permissionType
              newArr.push(item);
          }
        }
      })
      newArr.forEach(item => {

        // 有二级菜单
        if (item.meta.parentIndex !== item.meta.index) {
          if (menuArr[Number(item.meta.parentIndex) - 1] == undefined) {
            menuArr[Number(item.meta.parentIndex) - 1] = {
              index: item.meta.parentIndex,
              icon: item.meta.parentIcon,
              title: item.meta.parentTitle,
              permissionType: item.permissionType,
              menuItem: [],
            };
          }
          menuArr[Number(item.meta.parentIndex) - 1].menuItem.push({
            index: item.path,
            title: item.meta.title,
            permissionType: item.permissionType
          });
        }
        //无二级菜单
        else {
          menuArr[Number(item.meta.parentIndex) - 1] = {
            index: item.path,
            icon: item.icon,
            title: item.meta.title,
            permissionType: item.permissionType,
            menuItem: [],
          };
        }
      });
      menuArr=menuArr.filter(item=>item && item.index);
      setStore("permissionType",menuArr[0]?.permissionType);
      return menuArr;
    },
    // 菜單高亮
    activeIndex() {
      return this.$route.meta.activeTargetState
        ? this.$route.meta.activeTargetPath
        : this.$route.path;
    },
  },
  watch:{
    $route(to,from){
      let permissionsarr = JSON.parse(getStore("permissions"));
      permissionsarr.forEach((item)=>{
        // console.log(to.meta)
        if(to.meta.code === item.code){
          setStore("permissionType",item.permissionType);
        }
      })
      if(to.meta.keyCode == from.meta.keyCode && from.meta.keyCode == 102 && to.meta.keyCode == 102) {
        this.includedComponents = [];
        this.includedComponents.push(to.name);
      } else {
        this.includedComponents.push(to.name);
      }
      // if((to.path==="/cms/consumptionlog" || to.path==="/cms/userconsumptionlog") ){
      //   this.includedComponents.push("consumptionLog");
      // }else {
      //   this.includedComponents=this.includedComponents.filter(item => item != "consumptionLog");
      // }
      // if((to.path==="/cms/restaurantlist" || to.path==="/cms/restaurantdetail") ){
      //   this.includedComponents.push("restaurantList");
      // }else {
      //   this.includedComponents=this.includedComponents.filter(item => item != "restaurantList");
      // }
      // if((to.path==="/cms/couponlist" || to.path==="/cms/couponedit" || to.path==="/cms/details" ) ){
      //   this.includedComponents.push("couponlist");
      // }else {
      //   this.includedComponents=this.includedComponents.filter(item => item != "couponlist");
      // }
      // console.log(this.includedComponents);
    }
  },
  created() {
    let routeData = this.$route;
    this.defaultOpeneds = [routeData.meta.parentIndex];
    this.userName=getStore("userName");
    // if(routeData.path==="/cms/consumptionlog") {
    //   if(this.includedComponents.indexOf('consumptionLog') < 0) {
    //     this.includedComponents.push("consumptionLog");
    //   }
    // }
    // if(routeData.path==="/cms/restaurantlist") {
    //   if(this.includedComponents.indexOf('restaurantList') < 0) {
    //     this.includedComponents.push("restaurantList");
    //   }
    // }
    // if(routeData.path==="/cms/couponlist") {
    //   if(this.includedComponents.indexOf('couponlist') < 0) {
    //     this.includedComponents.push("couponlist");
    //   }
    // }
    if(routeData.meta.keyCode == 102) {
      this.includedComponents.push(routeData.name);
    }
    console.log(this.includedComponents);
  },
  methods: {
    openMenu(index) {
      if (index.indexOf(this.defaultOpeneds[0]) !== -1) return;
      this.defaultOpeneds = [index];
    },
    selectMenu(index){
      if(index==="/cms/consumptionlog"){
        this.includedComponents.push("consumptionLog");
      }else{
        this.includedComponents=[];
      }
    },
    handleCommand(command) {
      console.log(command);
    },
    logout() {
      resetRouter();
      removeStore("token");
      removeStore("userName");
      removeStore("permissions");
      this.$router.push({path:"/login"})
    },
  },
};
</script>

<style lang="scss">
.layout {
  height: 100%;
  .logo {
    width: 50px;
    height: 50px;
    display: block;
    border-radius: 5%;
    background: url("~@/assets/images/hlms2.jpg") center center no-repeat;
    background-size: cover;
    float: left;
    margin-top: 5px;
    margin-right: 10px;
  }
  .el-container {
    height: 100%;
  }
  .maxheight {
    height: calc(100% - 60px);
  }
  .el-header,
  .el-footer {
    background-color: #fff;
    color: #333;
    text-align: center;
    line-height: 60px;
  }
  .el-header {
    border-bottom: 1px solid #ccc;
    padding-right: 50px;
    .title {
      display: block;
      float: left;
      font-size: 24px;
      font-weight: 600;
    }
    .userinfo {
      float: right;
      .username {
        font-weight: 600;
        margin-right: 50px;
        height: 34px;
      }
      .logout {
        margin-right: 50px;
        font-weight: 600;
        color: #e6a23c;
      }
    }
  }
  .el-footer {
    border-top: 1px solid #ccc;
  }

  aside {
    background-color: #fff;
    color: #333;
    text-align: center;
    line-height: 200px;
    border-right: 1px solid #ccc;
  }

  .el-main {
    background-color: #e9eef3;
    color: #333;
    text-align: left;
    padding: 15px;
    flex: none;
    box-sizing: border-box;
    min-height: calc(100% - 61px);
    max-height: calc(100% - 61px);
  }

  body > .el-container {
    margin-bottom: 40px;
  }
  .el-menu {
    width: 200px;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 3px;/*设置滚动条的宽度*/
    }
    &::-webkit-scrollbar-thumb {
      background-color: #929292;
    }
    &::-webkit-scrollbar-track {
      background-color: #eee;
    }
  }
  // .el-menu--collapse {
  //   width: 65px;
  // }
  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
  }
  .el-submenu__title {
    text-align: left;
  }
  .el-menu-item {
    text-align: left;
    transition:border-color 0s;
  }
  .el-menu-item-group__title {
    padding: 0;
  }
  .el-menu-item.is-active {
    background: #409EFF;
    color: #fff;
  }
}
</style>
